import './App.css';
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function App() {
  const [formErrors, setFormErrors] = useState({});

  const validateForm = (e) => {
    const errors = {};
    const { name, email, message } = e.target.elements;

    if (!name.value) {
      errors.name = 'Name is required';
    }

    if (!email.value) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email.value)) {
      errors.email = 'Email address is invalid';
    }

    if (!message.value) {
      errors.message = 'Message is required';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateForm(e)) {
      return;
    }

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_EMAILJS_KEY
    )
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset();
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Grayson Morris</h1>
        <p>Cybersecurity Intern</p>
        <p>Computer Science Student</p>
      </header>
      <section className='About'>
        <h2>About Me</h2>
        <p>I am a Cybersecurity Intern at a local company in Fort Collins, CO. I am also a Senior Undergrad Computer Science student at Colorado State University. I have a passion for all things technology whether it be hardware or software. 
          I am also always trying to learn new things. I am always looking for new opportunities to grow and expand my skillset.</p>
      </section>
      <section className='Education'>
        <h2>Education</h2>
        <div className='School'>
          <h3>Colorado State University</h3>
          <p>BSc. Computer Science</p>
          <p>Expected Graduation: December 2024</p>
        </div>
        <div className='Certificate'>
          <h3>CompTIA</h3>
          <p>Security+</p>
          <p>Expeted Completion: December 2024</p>
        </div>
      </section>
      <section className='Experience'>
        <h2>Experience</h2>
        <div className='Job'>
          <h3>Woodward, Inc.</h3>
          <p>Cybersecurity Analyst/Complinace Intern</p>
          <p>Job Description</p>
        </div>
        <div className='Job'>
          <h3>Colorado State University</h3>
          <p>IT Support Specialist</p>
          <p>Job Description</p>
        </div>
      </section>
      <section className='Skills'>
        <h2>Skills</h2>
        <ul>
          <li>Python</li>
          <li>Java</li>
          <li>JavaScript</li>
          <li>React</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>SQL</li>
          <li>Git</li>
          <li>Linux</li>
          <li>Networking</li>
          <li>Security</li>
        </ul>
      </section>
      <section className='Projects'>
        <h2>Projects</h2>
        <div className='Project'>
          <h3>Ethical Hacking Tools</h3>
          <p>The goal of this project was to better understand some of the key tools used by offensive
            security professionals. I wanted to build some tools that could be used for basic OSINT, as well as
            tools for exploitation, such as a keylogger, password cracker and reverse shell. </p>
          <p>As an addition to this project I decided to build some low-level malware to better understand how it 
            works and how to defend against it effetively. It consists of a ransomware script that directly encrypts files and
            whole directories.</p>
          <p>Skills Learned: OSINT, Encryption/Decryption, Malware Identification, Critical Thinking, Problem-Solving</p>
        </div>
        <div className='Project'>
          <h3>Multiplayer Game</h3>
          <p>This is an in-progress project for my networks class. The goal here is to use socket programming
            in Python to create a multiplayer game played through a users local web browser. The game is similar to a combination of chess and a choose your
            own adventure game. The game is played on a 10x10 grid where each player takes turns moving their piece
            1 square at a time in any direction. The goal is to locate some hidden treasure. </p>
          <p>Skills Learned: Socket programming, IP Addressing, Python Unit Testing, Time Management, Team Collaboration</p>
        </div>
        <div className='Project'>
          <h3>Server Homelab</h3>
          <p>This was a larger project, for me. I was able to aquire some used networking equipment, an older server, and a few
            IRaspberry Pis. I set the PIs up to run as a cluster using Docker and Kubernetes to server as a test environment for
            my coding projects. The server I imaged to Proxmox a hypervisor of sorts it is currently running Windows Server for Active
            Directory, MacOS Sonoma, Debian for a media server and VPN service, a container for local AI, a container for Splunk, and a container for a PfSense firewall. </p>
          <p>Skills Learned: Networking, Virtualization, Containerization, Hypervisors, Linux, Clustered Computing, Perserverance, Adaptability</p>
        </div>
        <div className='Project'>
          <h3>Portfolio Website</h3>
          <p>You are looking at this project right now. I wanted to get better at some web development using React while following the current
            OWASP guidelines for security. To add, I utilized GitHub Actions to automate the code scanning and deployment process. For the scanning
            I used CodeQL, this allowed me to find and fix any security vulnerabilities not only in my code, but in web applications as a whole.
          </p>
          <p>Skills Learned: React, HTML, CSS, JavaScript, OWASP, Code Scanning, Automation, Written Communiation</p>
        </div>
      </section>
      <section className='Contact'>
        <h2>Contact Me</h2>
        <p>Email: grayson.r.morris@gmail.com</p>
        <p>Phone: 719.237.6876</p>
        <p>LinkedIn: <a href='https://www.linkedin.com/in/graysonmorris31/'>GraysonMorris31</a></p>
        <p>GitHub: <a href='https://github.com/GraysonMor31'>GraysonMor31</a></p>
        <h3>Or Send a Message</h3>
        <form onSubmit={sendEmail}>
          <label htmlFor='name'>Name:</label>
          <input type='text' id='name' name='name' required />
          {formErrors.name && <span className="error">{formErrors.name}</span>}
          
          <label htmlFor='email'>Email:</label>
          <input type='email' id='email' name='email' required />
          {formErrors.email && <span className="error">{formErrors.email}</span>}
          
          <label htmlFor='message'>Message:</label>
          <textarea id='message' name='message' required></textarea>
          {formErrors.message && <span className="error">{formErrors.message}</span>}
          
          <button type='submit'>Send</button>
        </form>
      </section>
      <footer className='Footer'>
        <p>&copy; 2024 Grayson Morris</p>
      </footer>
    </div>
  );
}

export default App;